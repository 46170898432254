<template>
  <section>
    <span class="text-center d-block f17">{{ exam.examName }}</span>
    <v-card class="d-flex flex-column align-items-center my-4 p-2" rounded="lg" flat color="grey lighten-4">
      <v-avatar class="my-2" size="96" rounded="lg">
        <v-img 
        :lazy-src="require('@/assets/images/profile-circle.png')"
        :src="
          exam.profilePicture != null && exam.profilePicture != ''
            ? exam.profilePicture
            : require('@/assets/images/profile.jpg')
        "></v-img>
      </v-avatar>
        <span class="my-1 f14 font-weight-bold">
          {{ exam.firstName + " " + exam.lastName }}
        </span>

        <span class="my-1 f13 grey--text text--darken-1">
          {{ $t('Age:') }} {{ exam.age }} {{ $t('Year') }}
        </span>

      <span class="my-1 f13 grey--text text--darken-1">
        {{ $t('Date of prescription:') }} {{ exam.createDate | moment("dddd , jD jMMMM jYYYY") }}
      </span>

      <span class="my-1 f13 grey--text text--darken-1">
        {{ $t('Date of completion:') }} {{ exam.updateDate | moment("dddd , jD jMMMM jYYYY") }}
      </span>
      
    </v-card>

    <span class="d-block mt-4 f16 font-weight-bold">{{ $t('Exam scale') }}</span>
    <div v-if="examResult && examResult.length > 0">
      <v-card class="my-2 py-4 px-6 d-flex justify-content-between" v-for="(scale, index) in examResult" :key="index" flat color="light-blue lighten-5">
        <span class="f14 font-weight-bold">{{ scale.title }}</span>
        <span class="f14 primary--text font-weight-bold">{{ scale.score }}</span>
      </v-card>
    </div>
    <span class="text-center f16 d-block mt-8" v-else>
      {{$t('No results found')}}
    </span>

    <span class=" d-block mt-4 f16 font-weight-bold">{{ $t('Exam guide') }}</span>
    <p class="f14" v-html="exam.drDescription"></p>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();


export default {
  data() {
    return {
      examCode: null,
      exam: {},
      examResult: [],
    }
  },
  methods: {
    getScalesPublic() {
      apiService.getPublicExamResult(this.examCode)
        .then((response) => {
          this.examResult = response.data;
        })
        .catch(() => {
          this.$router.go(-1);
        })
    },
    getScalesPrivate() {
      apiService.getPrivateExamResult(this.examCode)
        .then((response) => {
          this.examResult = response.data;
        })
        .catch(() => {
          this.$router.go(-1);
        })
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  mounted() {
    this.examCode = this.$route.params.examCode;

    if (this.$route.params.obj) {
      this.exam = this.$route.params.obj;
    }
    else {
      this.$router.go(-1);
    }

    if (this.$route.params.examType == "public") {
      this.getScalesPublic()
    }
    else if (this.$route.params.examType == "private") {
      this.getScalesPrivate()
    }
    else {
      this.$router.go(-1);
    }
  }
}
</script>
